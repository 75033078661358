/** @jsxImportSource @emotion/react */
import React from 'react'
import { KeyboardArrowRight } from '@mui/icons-material'
import { formatCadastreNumber, getAllOwners, isMainProperty, normalizeCadastreNumber } from 'utils/cadastre-utils'
import { PropertyResponseItem, PropertyType, PropertyUnit } from 'components/search/models/property-search-response'
import { MultiStringViewer } from 'components/general/multi-viewer/multi-string-viewer'
import { PropertyHeaderV2, propertyStyles as styles } from 'components/property/property-helper'
import { PropertyTableFilters } from 'components/property/shared/PropertyTableFilters'
import { usePropertyTable } from 'components/property/usePropertyTable'

type Props = {
    units: PropertyResponseItem[]
    title: string
    type: PropertyType
    onOpenProductDialog: (property: PropertyUnit) => void
    isNotConnectedPropertyAlertVisible?: boolean
}

export const DialogUnitTable: React.FC<Props> = React.memo(
    ({ units, title, onOpenProductDialog, type, isNotConnectedPropertyAlertVisible = false }) => {
        const { addressFilter, cadastreNumber, filteredUnits, onRemoveAddressFilter, onSearchUnits } =
            usePropertyTable(units)

        const rowContainerStyles = [styles.propertyLine, styles.propertyLineHeader]

        React.useEffect(() => {
            if (cadastreNumber && !isMainProperty(cadastreNumber)) {
                setTimeout(() => {
                    const element = document.getElementById(normalizeCadastreNumber(cadastreNumber))
                    element?.scrollIntoView({ behavior: 'smooth' })
                }, 0)
            }
        }, [cadastreNumber])

        const handleOpenDialog = (owners: string[], defaultUnit: PropertyResponseItem) => {
            const house = defaultUnit.housingUnits[0]

            onOpenProductDialog(house)
        }

        const handleOpenDialogWithKey = (
            event: React.KeyboardEvent<HTMLDivElement>,
            owners: string[],
            defaultUnit: PropertyResponseItem,
        ) => {
            if (event.key === 'Enter') {
                handleOpenDialog(owners, defaultUnit)
            }
        }

        return (
            <>
                <div>
                    <PropertyTableFilters
                        title={title}
                        onDeleteQueryAddress={onRemoveAddressFilter}
                        onSearch={(query) => onSearchUnits(query)}
                        queryAddress={addressFilter}
                        isNotConnectedPropertyAlertVisible={isNotConnectedPropertyAlertVisible}
                    />

                    <div>
                        <PropertyHeaderV2 showOwnerColumn={type === 'HousingCommunity'} />
                        {filteredUnits.map((unit, index) => {
                            const house = unit.housingUnits[0]
                            const owners = getAllOwners([unit])

                            return (
                                <div key={index} css={styles.contractedProperty}>
                                    <div
                                        css={rowContainerStyles}
                                        tabIndex={0}
                                        onKeyDown={(event) => handleOpenDialogWithKey(event, owners, unit)}
                                        onClick={() => handleOpenDialog(owners, unit)}
                                    >
                                        <div css={[styles.column1, styles.gridItem]}>
                                            {formatCadastreNumber(unit.cadastreNumber)}
                                        </div>
                                        {type === 'HousingCommunity' && (
                                            <div css={[styles.column2, styles.gridItem]}>
                                                <MultiStringViewer owners items={owners} />
                                            </div>
                                        )}
                                        <div css={[styles.column3, styles.gridItem]}>{house.address.addressText}</div>
                                        <div css={[styles.column4, styles.gridItem]}>{`${
                                            house.address.postalCode || ''
                                        } ${house.address.postalCodeName || ''}`}</div>
                                        <div css={[styles.column5, styles.gridItem]}>{house.buildingCodeName}</div>
                                        <div css={[styles.column6, styles.gridItem]}>
                                            {house.address.residentialNumber}
                                        </div>
                                        <div css={[styles.column7, styles.gridItem]}>
                                            <KeyboardArrowRight />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
        )
    },
)

DialogUnitTable.displayName = 'DialogUnitTable'
