import React from 'react'
import { PropertyResponse } from 'components/search/models/property-search-response'
import { LeasedPropertyRootView } from 'components/property/shared/leased-property-root-view'
import { SectionedPropertyParentView } from '../sectioned/SectionedPropertyParentView'
import { MainPropertyView } from '../main-property/MainPropertyView'

type Props = {
    land: PropertyResponse
}

export const LeasedSectionedPropertyView: React.FC<Props> = ({ land }) => {
    if (land.isRoot) {
        return <LeasedPropertyRootView land={land} />
    }

    if (land.isLeaseRoot) {
        return <SectionedPropertyParentView units={land.properties} type={land.type} />
    }

    return <MainPropertyView units={land.properties} />
}
