import React from 'react'
import { Box, Grid } from '@mui/material'
import { PropertyOverview } from 'components/property/shared/PropertyOverview'
import { flattenAndMapHouseUnits, getAllOwners, getMainPropertyCadastreNumber } from 'utils/cadastre-utils'
import { PropertyResponseItem, PropertyUnit } from 'components/search/models/property-search-response'
import { useTranslation } from 'react-i18next'
import { DialogUnitTable } from 'components/property/shared/DialogUnitTable'
import { HousingUnitProductsDialog } from 'components/property/shared/HousingUnitProductsDialog'

type HousingCommunityPropertyViewProps = {
    units: PropertyResponseItem[]
}

// unit[0] is the parent property that holds all other housing units related to this property
export const HousingCommunityPropertyView: React.FC<HousingCommunityPropertyViewProps> = ({ units }) => {
    const { t } = useTranslation()

    const [selectedHousingUnit, setSelectedHousingUnit] = React.useState<null | PropertyUnit>(null)

    const mainProperty = units?.[0] || undefined

    const sanitizedMainPropertyCadastreNr = getMainPropertyCadastreNumber(mainProperty?.cadastreNumber || null)

    // moved dialog up the top so need to memoize this in order to prevent PropertyOverview from rerendering
    const owners = React.useMemo(() => getAllOwners(units), [units])
    const allHouseUnits = React.useMemo(() => flattenAndMapHouseUnits(units), [units])

    const handleOpenProductDialog = React.useCallback((property: PropertyUnit) => {
        setSelectedHousingUnit(property)
    }, [])

    return (
        <>
            <Grid item xs={12}>
                <PropertyOverview
                    data={units}
                    isPropertySectioned={false}
                    sanitizedMainPropertyCadastreNr={sanitizedMainPropertyCadastreNr}
                    mainProperty={mainProperty}
                    allOwners={owners}
                />
                <Box mt={2}>
                    <DialogUnitTable
                        units={allHouseUnits}
                        title={t('HOUSING_UNITS', 'Bruksenheter ({{huCount}})', {
                            huCount: allHouseUnits.length,
                        })}
                        onOpenProductDialog={handleOpenProductDialog}
                        type="HousingCommunity"
                    />
                </Box>
            </Grid>
            <Grid item xs={12}></Grid>

            <HousingUnitProductsDialog
                isOpen={Boolean(selectedHousingUnit)}
                onClose={() => setSelectedHousingUnit(null)}
                housingUnit={selectedHousingUnit}
                mainPropertyMetaData={{
                    cadastreNumber: sanitizedMainPropertyCadastreNr,
                }}
            />
        </>
    )
}
