import { Alert, AlertTitle, Box, Chip, Paper } from '@mui/material'
import { PropertyTableTitle } from './PropertyTableTitle'
import { PropertySearchBar } from './PropertySearchBar'
import { useTranslation } from 'react-i18next'

type PropertyTableFiltersProps = {
    title: string
    onSearch: (query: string) => void
    queryAddress: string | null
    onDeleteQueryAddress: () => void
    isNotConnectedPropertyAlertVisible?: boolean
}

export const PropertyTableFilters: React.FC<PropertyTableFiltersProps> = ({
    title,
    onSearch,
    onDeleteQueryAddress,
    queryAddress,
    isNotConnectedPropertyAlertVisible = false,
}) => {
    const { t } = useTranslation()

    return (
        <Paper elevation={0} square>
            <PropertyTableTitle title={title} />
            {isNotConnectedPropertyAlertVisible && (
                <Box p={1.25} pb={0.5} maxWidth={600}>
                    <Alert severity="info">
                        <AlertTitle>
                            {t('not_connected_in_land_registery_title', 'Manglende adresse i matrikkelen')}
                        </AlertTitle>
                        {t(
                            'not_connected_in_land_registery_description',
                            'Seksjonen er ikke tilknyttet en adresse i matrikkelen. Velg aktuell adresse og bruksenhet.',
                        )}
                    </Alert>
                </Box>
            )}
            <PropertySearchBar onSearch={onSearch} />
            {queryAddress && (
                <Box pl={1.25} pb={1.25}>
                    <Chip label={queryAddress} onDelete={onDeleteQueryAddress} color="primary" />
                </Box>
            )}
        </Paper>
    )
}
