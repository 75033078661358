import { PropertyResponse, PropertyTypes } from '../search/models/property-search-response'
import { useIsOsloGuy } from '../auth/auth.slice'
import { HousingCommunityPropertyView } from './propertyViews/housing-community/HousingCommunityPropertyView'
import { OsloUserPropertyView } from './propertyViews/oslo/OsloUserPropertyView'
import { MainPropertyView } from './propertyViews/main-property/MainPropertyView'
import { SectionedPropertyView } from './propertyViews/sectioned/SectionedPropertyView'
import { LeasedPropertyView } from './propertyViews/leased/leased-property-view'
import { LeasedSectionedPropertyView } from './propertyViews/leased-sectioned/leased-sectioned-property-view'

type PropertyViewBuilderProps = {
    land: PropertyResponse
}

export const PropertyViewBuilder: React.FC<PropertyViewBuilderProps> = ({ land }) => {
    const isOsloUser = useIsOsloGuy()

    const { properties, type } = land

    if (isOsloUser) return <OsloUserPropertyView units={properties} />

    switch (type) {
        case PropertyTypes.HousingCommunity:
            return <HousingCommunityPropertyView units={properties} />
        case PropertyTypes.MainProperty:
            return <MainPropertyView units={properties} />
        case PropertyTypes.LeasedProperty:
            return <LeasedPropertyView land={land} />
        case PropertyTypes.SectionedProperty:
            return <SectionedPropertyView units={properties} type={type} />
        case PropertyTypes.LeasedSectionedProperty:
            return <LeasedSectionedPropertyView land={land} />
        default:
            return <OsloUserPropertyView units={properties} />
    }
}
